<template>
    <v-col cols="12" @employerPriceUpdate="employerPricing">
        <v-row v-if="isAdmin" >
            <v-col md="6">
                <v-select v-model.trim="employerPricing.pricingStructure" v-on:change="changeEmployerPrice" :disabled="fromParent" :required="!fromParent" :rules="customPricingRule" :items="pricingStructures" :item-text="(item) => item.text" :item-value="(item) => item.value" label=""></v-select>
            </v-col>
            <v-col md="6" v-if="isAdmin && showTiered">
                <v-select v-model.trim="employerPricing.tierStructure" v-on:change="changeTier" :disabled="fromParent" :required="!fromParent" :rules="customTieredRule" :items="tierStructures" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Tier Structure"></v-select>
            </v-col>
            <v-col md="6">
                <v-row>
                    <v-col md="6">
                        <v-select v-model.trim="employerPricing.hasFlatRate" :disabled="fromParent" :required="!fromParent" :items="dependentFeeOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Flat Rate?"></v-select>
                    </v-col>
                    <v-col md="6">
                        <v-text-field label="Flat Rate Fee" v-model.trim="employerPricing.flatRateFee" :disabled="fromParent" :required="!fromParent" :rules="[customRules.flatRateFeeRule(employerPricing.flatRateFee, employerPricing.hasFlatRate, fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" v-for="(item) in employerPricing.pricing" :key="item.sku">
                <v-col cols="12" v-if="showPmpm || showBlended || (showTiered && employerPricing.tierStructure != null) ">
                    <h4 class="font-weight-bold primary--text">Product: {{ getProductTitle(item.sku) }}</h4>
                </v-col>
                <v-row v-if="isAdmin && !showTiered">
                    <v-col cols="4" v-if="showPmpm && !showBlended">
                        <v-text-field label="PMPM" v-model.trim="item.data.memberPmpmPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberPmpmPrice, 'PMPM', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="showBlended & !showPmpm">
                        <v-text-field label="Blended" v-model.trim="item.data.memberBlendedPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberBlendedPrice, 'Blended', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                    </v-col>

                    <v-col cols="8">
                        <v-row>
                            <v-col md="4">
                                <v-select v-model.trim="item.data.hasDependentFee" :disabled="fromParent" :required="!fromParent" :items="dependentFeeOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Dependent Fee"></v-select>
                            </v-col>
                            <v-col md="4">
                                <v-text-field label="Max Dependents" v-model.trim="item.data.maxDependents" :disabled="fromParent" :required="!fromParent" :rules="[customRules.maxDependentRule(item.data.maxDependents, item.data.hasDependentFee, fromParent, getProductTitle(item.sku) )]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } },}" placeholder=""></v-text-field>
                            </v-col>
                            <v-col md="4">
                                <v-text-field label="Overage Fee" v-model.trim="item.data.overageFee" :disabled="fromParent" :required="!fromParent" :rules="[customRules.overageFeeRule(item.data.overageFee, item.data.hasDependentFee, fromParent, getProductTitle(item.sku))]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="isAdmin && showTiered" cols="12" class="py-2" style="margin-left: 10px;">
                    <v-row v-if="showTiered3">
                        <v-col md="4">
                            <v-text-field label="Member Only" v-model.trim="item.data.memberOnlyPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberOnlyPrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field label="Member Plus One"  v-model.trim="item.data.memberPlusPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberPlusPrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field label="Family" v-model.trim="item.data.memberFamilyPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberFamilyPrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row md="12" v-if="showTiered4">
                        <v-col md="3">
                            <v-text-field label="Member Only" v-model.trim="item.data.memberOnlyPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberOnlyPrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field label="Member Plus Spouse" v-model.trim="item.data.memberPlusSpousePrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberPlusSpousePrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field label="Member Plus Dependent" v-model.trim="item.data.memberPlusDependentPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberPlusDependentPrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>                            
                        <v-col md="3">
                            <v-text-field label="Family" v-model.trim="item.data.memberFamilyPrice" :disabled="fromParent" :required="!fromParent" :rules="[customRules.pricingRule(item.data.memberFamilyPrice, 'Tiered', fromParent)]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-col md="12" v-if="showTiered4 || showTiered3">
                        <v-row>
                            <v-col md="4">
                                <v-select v-model.trim="item.data.hasDependentFee" :disabled="fromParent" :required="!fromParent" :items="dependentFeeOptions" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Dependent Fee"></v-select>
                            </v-col>
                            <v-col md="4">
                                <v-text-field label="Max Dependents" v-model.trim="item.data.maxDependents" :disabled="fromParent" :required="!fromParent" :rules="[customRules.maxDependentRule(item.data.maxDependents, item.data.hasDependentFee, fromParent, getProductTitle(item.sku))]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } },}" placeholder=""></v-text-field>
                            </v-col>
                            <v-col md="4">
                                <v-text-field label="Overage Fee" v-model.trim="item.data.overageFee" :disabled="fromParent" :required="!fromParent" :rules="[customRules.overageFeeRule(item.data.overageFee, item.data.hasDependentFee, fromParent, getProductTitle(item.sku))]" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" placeholder="" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select 
                            v-model.trim="item.settings.enableApps" required
                            :items="enabledAppOptions" :item-text="(item) => item.text" 
                            :item-value="(item) => item.value" label="Enable Apps"></v-select>
                    </v-col>
                    <v-col>
                        <v-select 
                            v-model.trim="item.settings.selectedApps" :required="item.settings.enableApps == 'Yes'" 
                            :items="applicationPartners" :item-text="(item) => item.name" 
                            :item-value="(item) => item.partnerID" label="Select Apps" multiple></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-col>

</template>
<script>
import { useEmployerStore } from "../../stores/employer";
import { usePartnerStore } from "../../stores/partner";
import { employerPricingStructureTypes } from "../../commons/employer";
import { lookupTypes } from '../../data/definitions/lookupTypes';

export default {
    name: "EmployerPricing",
    props: ['isAdmin', 'allEmployerProducts', 'isSave', 'fromParent', 'employerID', 'emCustomerType', 'newEmployer', 'parentEmployerID'],
    components: {
    },
    setup() {
        const employerStore = useEmployerStore();
        const partnerStore = usePartnerStore();
        return { employerStore, partnerStore };
    },
    data(){
        return {
            employerPricing: {
                pricingStructure: null,
                tierStructure: null,
                pricing: [],
                isFromParent: false,
                hasFlatRate : null,
                flatRateFee : null
            },
            parentEmployerPricing:  {
                pricingStructure: null,
                tierStructure: null,
                pricing: [],
                isFromParent: false,
                hasFlatRate : null,
                flatRateFee : null
            },
            showTiered: false,
            showPmpm: false,
            showBlended: false,
            showTiered3: false,
            showTiered4: false,
            pricingRule: [
                (v) => !!v || 'Please select pricing structure'
            ],
            tieredRule: [
                (v) => !!v || 'Please select tier structure'
            ],
            pmpmPriceRule: [
                (v) => !!v || 'Please enter PMPM amount'
            ],
            blendedPriceRule: [
                (v) => !!v || 'Please enter blended amount'
            ],
            tieredPriceRule: [
                (v) => !!v || 'Please enter tiered amount'
            ],
            customRules: {
                pricingRule(value, type, isFromParent){
                    if(isFromParent) return [];
                    if(!value)
                        return `Please enter ${type} amount`;
                    return (/^(?!0\.0{1,2}$)(0|[1-9][0-9]{0,2})(\.\d{1,2})?$/.test(value) && !(value.toString().endsWith('.')) ) || `Please enter ${type} amount from 0 to 999.99`;
                },
                maxDependentRule(value, hasFee, isFromParent, type){
                    if(isFromParent || ((hasFee == null || hasFee == undefined || hasFee == 0) && !value))
                        return true; 
                    if(!value)
                        return `Please enter ${type} max dependent value`;
                    return (/^\d{1,2}$/.test(value) && !(value.toString().endsWith('.')) && !(value.toString().startsWith('0')) ) || `Please enter ${type} max dependent value from 1 to 99`;
                },
                overageFeeRule(value, hasFee, isFromParent, type){
                    if(isFromParent || ((hasFee == null || hasFee == undefined || hasFee == 0) && !value))
                        return true;
                    if(!value)
                        return `Please enter ${type} overage fee`;
                    return (/^(?!0\d)(?!0(\.0{1,2})?$)(\d{1,3}(\.\d{1,2})?|999(\.00?)?)$/.test(value) && !(value.toString().endsWith('.')) ) || `Please enter ${type} overage fee amount from 0.01 to 999.99`;
                },
                flatRateFeeRule(value, hasFee, isFromParent){
                    if(isFromParent || ((hasFee == null || hasFee == undefined || hasFee == 0) && !value))
                        return true;
                    if(!value)
                        return `Please enter flat rate fee`;
                    return (/^(?!0+(\.00?)?$)(\d{1,6}(\.\d{1,2})?|999999(\.99?)?|99999(\.\d{1,2})?|999(\.99?)?|99(\.\d{1,2})?|9(\.\d{1,2})?|0(\.\d{1,2})?)$/.test(value) && !(value.toString().endsWith('.')) ) || `Please enter flat rate fee amount from 0.01 to 999,999.99`;
                }
            },
            pricingStructures: [
                { text: "PMPM", value: "1" },
                { text: "Blended", value: "2" },
                { text: "Tiered", value: "3" }
            ],
            tierStructures: [
                { text: "3 Tier Grouping", value: "1" },
                { text: "4 Tier Grouping", value: "2" }
            ],
            dependentFeeOptions: [
                {text: "No", value: 0},
                {text: "Yes", value: 1},
            ],
            parentProductList: [],
            isInitialized: false,
            employerProducts: this.allEmployerProducts,
            initialProductsLoaded: false,
            enabledAppOptions: [
                {text: "Not Set", value: null},
                {text: "Yes", value: "Yes"},
                {text: "No", value: "No"}
            ],
            applicationPartners: []
        }
    },
    mounted(){
        this.initializePricingPerProduct(this.employerProducts);
        this.setInitials();
        this.loadApplicationPartners();
    },
    watch: {
        emCustomerType: function(val){
            if(this.employerPricing == null || this.employerPricing.pricingStructure == null || this.employerPricing.pricing == null || this.employerPricing.pricing.length < 0)
                this.initializeIfNoPricing();
            else{
                let pricingWithValue = [];
                this.employerPricing.pricing.filter(x => {
                    if(x.data != null && (x.data.memberBlendedPrice || x.data.memberOnlyPrice || x.data.memberPlusPrice || x.data.memberPlusDependentPrice 
                                            || x.data.memberPlusSpousePrice || x.data.memberFamilyPrice || x.data.memberPmpmPrice || x.data.memberBlendedPrice)){
                        pricingWithValue.push(x);
                    }
                });

                if(pricingWithValue.length > 0){
                    this.employerPricing.pricing.forEach(x => {
                        if(x.data != null){
                            if(val && val == 'manifest')
                                x.data.hasDependentFee = 1;
                            else{
                                x.data.hasDependentFee = 0;
                                x.data.overageFee = null;
                                x.data.maxDependents = null;
                            }
                        }
                    });
                }

                if(pricingWithValue.length < 1 && !this.fromParent)
                    this.initializeIfNoPricing();
            }
        },
        allEmployerProducts(products){
            this.isInitialized = false;
            this.employerProducts = products;
        },
        employerProducts: function(products){
            this.initializePricingPerProduct(products);
        },
        isSave(save){
            if(save){
                this.employerPricing.isFromParent = this.fromParent;
                this.$emit('employerPriceUpdate', this.employerPricing);
            }
        }
    },
    computed: {
        customPricingRule(){
            return !this.fromParent ? this.pricingRule : [];
        },
        customPMPMPricingRule(){
            return !this.fromParent ? this.pmpmPriceRule : [];
        },
        customBlendedPriceRule(){
            return !this.fromParent ? this.blendedPriceRule : [];
        },
        customTieredPriceRule(){
            return !this.fromParent ? this.tieredPriceRule : [];
        },
        customTieredRule(){
            return !this.fromParent ? this.tieredRule : [];
        },
    },
    methods: {
        setInitials(){
            if(!this.fromParent)
                this.loadEmployerPricing();
            else if(this.fromParent)
                this.loadParentEmployerPricing();
        },
        loadEmployerPricing(){
            //load employer pricing structure
          this.employerStore.getEmployerPricing(this.employerID)
            .then((data) => {
                this.setExistingValues(data);
          }).catch( () => {
                this.initializeIfNoPricing();
            });
        },
        loadParentEmployerPricing(){
            if(this.fromParent && this.newEmployer){
                this.employerStore.getEmployerPricing(this.parentEmployerID)
                    .then((data) => {
                        this.parentEmployerPricing = data;
                        this.setExistingValues(data);
                }).catch( () => {
                        this.initializeIfNoPricing();
                });
            }
            else{
                this.employerStore.getEmployerPricingWithHeirarchy(this.employerID)
                .then((data) => {
                    this.parentEmployerPricing = data;
                    this.setExistingValues(data);
                }).catch( () => {
                    this.initializeIfNoPricing();
                });
            }
        },
        loadApplicationPartners(){
            this.partnerStore.getPartnersByType(lookupTypes.partnerType.application)
                .then((data) => {
                    this.applicationPartners = data;
                });
        },
        setExistingValues(empPrice){
            if(empPrice && (empPrice != null || empPrice != undefined)){
                if(empPrice.type.toLowerCase() === employerPricingStructureTypes.pmpm){
                    this.employerPricing.pricingStructure = '1';
                    this.employerPricing.hasFlatRate = empPrice.pricingDetails?.hasFlatRate ?? null;
                    this.employerPricing.flatRateFee = empPrice.pricingDetails?.flatRateFee ?? null;

                    empPrice.pricings.forEach( (price) => {
                        this.employerPricing.pricing.forEach( (x) => {
                            if(x.sku.toLowerCase() === price.sku.toLowerCase()){
                                x.data.memberPmpmPrice = price.data.memberPmpmPrice;
                                x.data.hasDependentFee = price.data.hasDependentFee ?? null;
                                x.data.maxDependents = price.data.maxDependents ?? null;
                                x.data.overageFee = price.data.overageFee ?? null;
                            }
                        });
                    });
                    this.showPmpm = true;
                }
                else if(empPrice.type.toLowerCase() === employerPricingStructureTypes.blended){
                    this.employerPricing.pricingStructure = '2';
                    this.employerPricing.hasFlatRate = empPrice.pricingDetails?.hasFlatRate ?? null;
                    this.employerPricing.flatRateFee = empPrice.pricingDetails?.flatRateFee ?? null;

                    empPrice.pricings.forEach( (price) => {
                        this.employerPricing.pricing.forEach( (x) => { 
                            if(x.sku.toLowerCase() === price.sku.toLowerCase()){
                                x.data.memberBlendedPrice = price.data.memberBlendedPrice;
                                x.data.hasDependentFee = price.data.hasDependentFee ?? null;
                                x.data.maxDependents = price.data.maxDependents ?? null;
                                x.data.overageFee = price.data.overageFee ?? null;
                            }
                        });
                    });
                    this.showBlended = true;
                }
                else if(empPrice.type.toLowerCase() === employerPricingStructureTypes.tiered3){
                    this.employerPricing.pricingStructure = '3';
                    this.employerPricing.tierStructure = '1';
                    this.employerPricing.hasFlatRate = empPrice.pricingDetails?.hasFlatRate ?? null;
                    this.employerPricing.flatRateFee = empPrice.pricingDetails?.flatRateFee ?? null;

                    empPrice.pricings.forEach( (price) => {
                        this.employerPricing.pricing.forEach( (x) => { 
                            if(x.sku.toLowerCase() === price.sku.toLowerCase()){
                                x.data.memberOnlyPrice = price.data.memberOnlyPrice;
                                x.data.memberPlusPrice = price.data.memberPlusPrice;
                                x.data.memberFamilyPrice = price.data.memberFamilyPrice;
                                x.data.hasDependentFee = price.data.hasDependentFee ?? null;
                                x.data.maxDependents = price.data.maxDependents ?? null;
                                x.data.overageFee = price.data.overageFee ?? null;
                            }
                        });
                    });
                    this.showTiered = true;
                    this.showTiered3 = true;
                }
                else if(empPrice.type.toLowerCase() === employerPricingStructureTypes.tiered4){
                    this.employerPricing.pricingStructure = '3';
                    this.employerPricing.tierStructure = '2';
                    this.employerPricing.hasFlatRate = empPrice.pricingDetails?.hasFlatRate ?? null;
                    this.employerPricing.flatRateFee = empPrice.pricingDetails?.flatRateFee ?? null;

                    empPrice.pricings.forEach( (price) => {
                        this.employerPricing.pricing.forEach( (x) => { 
                            if(x.sku.toLowerCase() === price.sku.toLowerCase()){
                                x.data.memberOnlyPrice = price.data.memberOnlyPrice;
                                x.data.memberPlusDependentPrice = price.data.memberPlusDependentPrice;
                                x.data.memberPlusSpousePrice = price.data.memberPlusSpousePrice;
                                x.data.memberFamilyPrice = price.data.memberFamilyPrice;
                                x.data.hasDependentFee = price.data.hasDependentFee ?? null;
                                x.data.maxDependents = price.data.maxDependents ?? null;
                                x.data.overageFee = price.data.overageFee ?? null;
                            }
                        });
                    });
                    this.showTiered = true;
                    this.showTiered4 = true;
                }
            }
        },
        getProductTitle(id){
            return this.allEmployerProducts.find(x => x.skuid === id)?.name;
        },
        initializePricingPerProduct(products){
            products.forEach( (product) => {
                if(!this.initialProductsLoaded){
                    this.setInitials();
                }
                let pricing = undefined;
                if(this.fromParent){
                    let parentProductData = this.parentEmployerPricing?.pricings?.find(x => x.sku.toLowerCase() == product.skuid.toLowerCase());
                    let productData = this.employerPricing.pricing?.find(x => x.sku.toLowerCase() == product.skuid.toLowerCase());
                    if(!productData){
                        if(parentProductData){
                            pricing = parentProductData;
                        }
                        else{
                            pricing = {
                                sku: product.skuid,
                                data: {
                                    memberPmpmPrice: null,
                                    memberBlendedPrice: null,
                                    memberOnlyPrice: null,
                                    memberPlusPrice: null,
                                    memberPlusDependentPrice: null,
                                    memberPlusSpousePrice: null,
                                    memberFamilyPrice: null,
                                    hasDependentFee: null,
                                    maxDependents: null,
                                    overageFee: null
                                },
                                settings: {
                                    enableApps: null,
                                    selectedApps: []
                                }
                            };                            
                        }
                    } 
                }
                else{
                    let productData = this.employerPricing.pricing.find(x => x.sku.toLowerCase() == product.skuid.toLowerCase());
                    if(!productData){
                        let hasDeependentFee = this.emCustomerType == 'manifest' ? 1 : 0;
                        pricing = {
                            sku: product.skuid,
                            data: {
                                memberPmpmPrice: null,
                                memberBlendedPrice: null,
                                memberOnlyPrice: null,
                                memberPlusPrice: null,
                                memberPlusDependentPrice: null,
                                memberPlusSpousePrice: null,
                                memberFamilyPrice: null,
                                hasDependentFee: hasDeependentFee,
                                maxDependents: null,
                                overageFee: null
                            },
                            settings: {
                                enableApps: null,
                                selectedApps: []
                            }
                        };
                    }
                }
                this.initialProductsLoaded = true;

                if(pricing){
                    if(product.settings){
                        pricing.settings = product.settings;
                    }
                    this.employerPricing.pricing.push(pricing);
                }
            });
            //filter and remove removed products
            let removeList = [];
            let productSkus = products.map(x => x.skuid);
            this.employerPricing.pricing.forEach( (x) => {
                if(!productSkus.includes(x.sku)){
                    removeList.push(this.employerPricing.pricing.indexOf(x));
                }
            });
            if(removeList){
                removeList.forEach( (x) => {
                    this.employerPricing.pricing.splice(x, 1);
                });
            }
            this.isInitialized = true;
        },
        initializeIfNoPricing(){
            let newPricingList = [];
            this.employerProducts.forEach( (product) => {
                let newPricing = undefined;
                if(this.emCustomerType && this.emCustomerType == 'manifest'){
                    this.employerPricing.pricingStructure = '3';
                    this.employerPricing.tierStructure = '2';
                    newPricing = {
                        sku: product.skuid,
                        data: {
                            memberPmpmPrice: null,
                            memberBlendedPrice: null,
                            memberOnlyPrice: null,
                            memberPlusPrice: null,
                            memberPlusDependentPrice: null,
                            memberPlusSpousePrice: null,
                            memberFamilyPrice: null,
                            hasDependentFee: 1,
                            maxDependents: null,
                            overageFee: null
                        },
                        settings: {
                            enableApps: null,
                            selectedApps: []
                        }
                    };
                    this.showTiered = true;
                    this.showTiered3 = false;
                    this.showTiered4 = true;
                    this.showPmpm = false;
                    this.showBlended = false;
                } else{
                    this.employerPricing.pricingStructure = '3';
                    this.employerPricing.tierStructure = '1';
                    newPricing = {
                        sku: product.skuid,
                        data: {
                            memberPmpmPrice: null,
                            memberBlendedPrice: null,
                            memberOnlyPrice: null,
                            memberPlusPrice: null,
                            memberPlusDependentPrice: null,
                            memberPlusSpousePrice: null,
                            memberFamilyPrice: null,
                            hasDependentFee: 0,
                            maxDependents: null,
                            overageFee: null
                        },
                        settings: {
                            enableApps: null,
                            selectedApps: []
                        }
                    };
                    this.showTiered = true;
                    this.showTiered3 = true;
                    this.showTiered4 = false;
                    this.showPmpm = false;
                    this.showBlended = false;
                }
                
                if(newPricing && product.settings){
                    newPricing.settings = product.settings;
                }
                newPricingList.push(newPricing);
            });
            
            this.employerPricing.pricing = [...newPricingList];
        },
        changeEmployerPrice(value){
            if(value === '1'){
                this.employerPricing.pricing.forEach( (item) => {
                    item.data.memberBlendedPrice = null;
                    item.data.memberOnlyPrice = null;
                    item.data.memberPlusPrice = null;
                    item.data.memberPlusDependentPrice = null;
                    item.data.memberPlusSpousePrice = null;
                    item.data.memberFamilyPrice = null;
                    item.data.hasDependentFee = null;
                    item.data.maxDependents = null;
                    item.data.overageFee = null;
                });
                this.employerPricing.tierStructure = null;
                this.showTiered = false;
                this.showTiered3 = false;
                this.showTiered4 = false;
                this.showBlended = false;
                this.showPmpm = true;

            } else if(value === '2'){
                this.employerPricing.pricing.forEach( (item) => {
                    item.data.memberPmpmPrice = null;
                    item.data.memberOnlyPrice = null;
                    item.data.memberPlusPrice = null;
                    item.data.memberPlusDependentPrice = null;
                    item.data.memberPlusSpousePrice = null;
                    item.data.memberFamilyPrice = null;
                    item.data.tierStructure = null;
                    item.data.hasDependentFee = null;
                    item.data.maxDependents = null;
                    item.data.overageFee = null;
                });
                this.employerPricing.tierStructure = null;
                this.showTiered = false;
                this.showTiered3 = false;
                this.showTiered4 = false;
                this.showPmpm = false;
                this.showBlended = true;
            } else if(value === '3'){
                this.employerPricing.pricing.forEach( (item) => {
                    item.data.memberPmpmPrice = null;
                    item.data.memberBlendedPrice = null;
                    item.data.hasDependentFee = null;
                    item.data.maxDependents = null;
                    item.data.overageFee = null;
                });
                this.showTiered = true;
                this.showBlended = false;
                this.showPmpm = false;
            }
        },
        changeTier(value){                        
            if(this.employerPricing.pricingStructure === '3'){
                if(value === '1'){
                    this.employerPricing.pricing.forEach( (item) => {
                        item.data.memberPmpmPrice = null;
                        item.data.memberBlendedPrice = null;
                        item.data.memberPlusSpousePrice = null;
                        item.data.memberPlusDependentPrice = null;
                    });

                    this.showTiered3 = true;
                    this.showTiered4 = false;
                } else if(value === '2'){
                    this.employerPricing.pricing.forEach( (item) => {
                        item.data.memberPmpmPrice = null;
                        item.data.memberBlendedPrice = null;
                        item.data.memberPlusPrice = null;
                    });
                    
                    this.showTiered3 = false;
                    this.showTiered4 = true;
                }
            }
        }
    }
}
</script>
<style>
.tableRow .itemRow:nth-child(odd) {
    background-color: rgb(191, 214, 255) ;
}
</style>