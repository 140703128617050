import apiClient from "../services/api";
import { defineStore } from "pinia";

export const usePartnerStore = defineStore("partner", {
    state: () => ({
    }),
    actions: {
        async getPartnersByType(partnerType) {
          const data = await apiClient.get(`/partner/getPartnersByType?partnerType=${partnerType}`);
          return data.data;
        }
    }
});